import axios from "axios";
import { toast } from "react-toastify";
import { errorHandling } from "../../helper";

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';


export const USER_DETAIL_REQUEST = 'USER_DETAIL_REQUEST';
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS';
export const USER_DETAIL_FAILURE = 'USER_DETAIL_FAILURE';

export const SEND_NOTIFICATION_REQUEST = 'SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAILURE = 'SEND_NOTIFICATION_FAILURE';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const GLOBAL_SEARCH_PANEL_REQUEST = 'GLOBAL_SEARCH_PANEL_REQUEST';
export const GLOBAL_SEARCH_PANEL_SUCCESS = 'GLOBAL_SEARCH_PANEL_SUCCESS';
export const GLOBAL_SEARCH_PANEL_FAILURE = 'GLOBAL_SEARCH_PANEL_FAILURE';

export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAILURE = 'ADD_ADDRESS_FAILURE';

export const userList = (setIsLoading, region, incomplete) => {
    return async (dispatch) => {
        try {
            setIsLoading(true);
            dispatch({ type: USER_LIST_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/list/of/users/v1?regionName=${region}&incomplete=${incomplete}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                },
            });
            dispatch({ type: USER_LIST_SUCCESS, payload: response.data });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            errorHandling(dispatch, USER_LIST_FAILURE, error);
        }
    }
};



export const userDetail = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: USER_DETAIL_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/get/users/${id}/v2`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: USER_DETAIL_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, USER_DETAIL_FAILURE, error);
        }
    }
};

export const userUpdateDetail = (id, data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: USER_UPDATE_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/update/user/profile/${id}/v1`;
            const response = await axios.put(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: USER_UPDATE_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, USER_UPDATE_FAILURE, error);
        }
    }
};

export const sendNotification = (data, clearData, setSelectedIds, setShow) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_NOTIFICATION_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/fcm/send/notification/to/selected/users/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: SEND_NOTIFICATION_SUCCESS, payload: response.data });
            clearData();
            setSelectedIds([]);
            setShow(false);
            toast("Notification sent successfully");
        } catch (error) {
            errorHandling(dispatch, SEND_NOTIFICATION_FAILURE, error);
        }
    }
};

export const sendEmail = (data, clearData, setSelectedIds, setShow) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_EMAIL_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/send/email/to/selected/users/admin/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: SEND_EMAIL_SUCCESS, payload: response.data });
            clearData();
            setSelectedIds([]);
            setShow(false);
            toast("Email sent successfully");
        } catch (error) {
            errorHandling(dispatch, SEND_EMAIL_FAILURE, error);
        }
    }
};


export const searchResult = (keyWord) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GLOBAL_SEARCH_PANEL_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/search/bar/result/v1?searchInput=${keyWord}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: localStorage.getItem('token')
                },
            });
            dispatch({ type: GLOBAL_SEARCH_PANEL_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, GLOBAL_SEARCH_PANEL_FAILURE, error);
        }
    }
};

export const addAddressForUser = (data, userId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ADD_ADDRESS_REQUEST });
            const url = `${process.env.REACT_APP_API_AWS_URL}/admin/add/user/address/${userId}/v1`;
            const response = await axios.post(url, data, {
                headers: {
                    Authorization: localStorage.getItem('token')
                }
            });
            dispatch({ type: ADD_ADDRESS_SUCCESS, payload: response.data });
        } catch (error) {
            errorHandling(dispatch, ADD_ADDRESS_FAILURE, error);
        }
    }
};
