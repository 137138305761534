import * as productActions from "./product.actions";

export const productFeatureKey = "productInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  list: [],
  details: {},
  brands: [],
  categories: [],
};

export const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case productActions.PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case productActions.PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload.data,
      };

    case productActions.PRODUCT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        list: [],
      };

    case productActions.PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case productActions.PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        details: payload.data,
      };

    case productActions.PRODUCT_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        details: {},
      };

    case productActions.PRODUCT_BRANDS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case productActions.PRODUCT_BRANDS_SUCCESS:
      return {
        ...state,
        loading: false,
        brands: payload.data,
      };

    case productActions.PRODUCT_BRANDS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        brands: [],
      };

    case productActions.PRODUCT_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case productActions.PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: payload.data,
      };

    case productActions.PRODUCT_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        categories: [],
      };

    // product update

    case productActions.PRODUCT_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case productActions.PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        details: payload.data,
      };

    case productActions.PRODUCT_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        brands: [],
      };

      // product update status(verify)

    case productActions.PRODUCT_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case productActions.PRODUCT_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case productActions.PRODUCT_UPDATE_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    default:
      return state;
  }
};
