import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  FormControl,
  FormGroup,
  Modal,
  ModalBody,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPriceCharges,
  updatePriceCharges,
} from "../../../redux/setting/setting.actions";
import validations from "../../../validations";
import { toast } from "react-toastify";

const PriceChargeSetting = () => {
  const [data, setData] = useState({});
  const [modalShow, setModalShow] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { priceCharges, isStatusChange } = useSelector(
    (state) => state.settingInfo
  );

  const prevProps = useRef({ isStatusChange }).current;

  useEffect(() => {
    if (prevProps.isStatusChange !== isStatusChange) {
      if (isStatusChange) {
        dispatch(getPriceCharges());
      }
    }

    return () => {
      prevProps.isStatusChange = isStatusChange;
    };
  }, [dispatch, prevProps, isStatusChange]);

  useEffect(() => {
    if (priceCharges && Object.keys(priceCharges).length > 0) {
      setData(priceCharges);
    } else {
      setData({});
    }
  }, [priceCharges]);

  useEffect(() => {
    dispatch(getPriceCharges());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!data?.platformFee || !data?.convenientFee || !data?.gst){
      toast("All the fields are required!")
    } else {
      dispatch(updatePriceCharges(priceCharges?._id, data));
    }
  };

  return (
    <>
      {loading ? (
        <div className="w-100 d-flex">
          <div
            className="spinner-border text-primary mx-auto"
            role="status"
          ></div>
        </div>
      ) : (
        <>
          {data && Object.keys(priceCharges).length > 0 ? (
            <div>
              <div className="page-header">
                <h3 className="page-title">Price Charges Setting</h3>
              </div>
              <Row className="d-flex">
                <Col md={8} className="mx-auto">
                  <div className="card">
                    <div className="card-body">
                      <Row>
                        <Col md={12} className="profile_info px-4">
                          <form onSubmit={handleSubmit}>
                            <Row>
                              <FormGroup>
                                <label>Platform Fee </label>
                                <FormControl
                                  type="text"
                                  placeholder="Enter Platform Fees"
                                  min="0"
                                  value={data?.platformFee}
                                  onChange={(e) => {
                                    let value = parseFloat(e.target.value);
                                    if (isNaN(value)) value = "";
                                    else if (value < 0) value = 0;
                                    setData({
                                      ...data,
                                      platformFee: value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Row>
                            <Row>
                              <FormGroup>
                                <label>Convenient Fee</label>
                                <FormControl
                                  type="text"
                                  placeholder="Enter Convenient Fees"
                                  min="0"
                                  value={data?.convenientFee}
                                  onChange={(e) => {
                                    let value = parseFloat(e.target.value);
                                    if (isNaN(value)) value = "";
                                    else if (value < 0) value = 0;
                                    setData({
                                      ...data,
                                      convenientFee: value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Row>
                            <Row>
                              <FormGroup>
                                <label>GST (in %)</label>
                                <FormControl
                                  type="text"
                                  placeholder="Enter GST"
                                  min="0"
                                  max="100"
                                  value={data?.gst}
                                  onChange={(e) => {
                                    let value = parseFloat(e.target.value);
                                    if (isNaN(value)) value = "";
                                    else if (value < 0) value = 0;
                                    else if (value > 100) value = 100;
                                    setData({ ...data, gst: value });
                                  }}
                                />
                              </FormGroup>
                            </Row>
                            <Row>
                              <FormGroup>
                                <label>GST for retail (in %)</label>
                                <FormControl
                                  type="text"
                                  placeholder="Enter GST for retail"
                                  min="0"
                                  value={data?.gstForRetail}
                                  onChange={(e) => {
                                    let value = parseFloat(e.target.value);
                                    if (isNaN(value)) value = "";
                                    else if (value < 0) value = 0;
                                    setData({
                                      ...data,
                                      gstForRetail: value,
                                    });
                                  }}
                                />
                              </FormGroup>
                            </Row>
                            <Row>
                              <button
                                type="submit"
                                className="btn_primary_outline_sm ms-auto mt-3"
                              >
                                UPDATE
                              </button>
                            </Row>
                          </form>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <h2 className="text-center text-muted mt-3">No profile found</h2>
          )}
        </>
      )}
    </>
  );
};

export default PriceChargeSetting;
