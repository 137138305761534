import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as userDropdownActions from "../../../../redux/dropdowns/userDropdown.action";
import * as petActions from "../../../../redux/pet/pet.actions";
import validations from "../../../../validations";
import { toast } from "react-toastify";
import { imageBaseUrl, uploadImage } from "../../../../helper";

const PetUpdateModal = ({ show, setShow, petDetails }) => {

    const dispatch = useDispatch();
    const breedOfPetsCategoryList = useSelector((state) => state.userDropdownInfo.list);
    const loading = useSelector((state) => state.userDropdownInfo.loading);
    const [imageLoading, setImageLoading] = useState(false);
    const [profileImage, setProfileImage] = useState(null);

    const [formData, setFormData] = useState({
        petImage: "",
        petName: "",
        breed: "",
        dateOfBirth: "",
        gender: "",
        weight: "",
        colour: "",
        petCategory: "",
    });


    useEffect(() => {

        const {
            petImage = "",
            petName = "",
            breed = {},
            dateOfBirth = "",
            gender = "",
            weight = "",
            colour = "",
            petCategory = "",
        } = petDetails;

        setFormData({
            petImage,
            petName,
            breed: breed._id,
            dateOfBirth,
            gender,
            weight,
            colour,
            petCategory,
        });

    }, [petDetails, show]);

    const handleClose = () => {
        setShow(false);
        setFormData({
            petImage: "",
            petName: "",
            breed: "",
            dateOfBirth: "",
            gender: "",
            weight: "",
            colour: "",
            petCategory: "",
        });
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let uploadImageUrl = null;

        console.log(formData?.petImage);

        if (formData?.petImage && typeof formData?.petImage !== "string") {
            setImageLoading(true);
            const url = "/pet/admin/upload/pet/profile/image/v1"

            uploadImageUrl = await uploadImage(url, formData?.petImage, setProfileImage);

            setImageLoading(false);
        }

        const updatedData = {
            ...formData,
            petImage: uploadImageUrl || formData?.petImage,
            petCategory: formData.petCategory?._id || petDetails?.petCategory?._id || ""
        };

        await dispatch(petActions.updatePet(petDetails._id, updatedData));
        handleClose();
        toast.success("Pet information updated successfully");
        await dispatch(petActions.petDetail(petDetails._id));
    }

    useEffect(() => {
        dispatch(userDropdownActions.breedOfPetsDropdownList());
    }, []);

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Update Pet Information</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form className="p-3" onSubmit={handleSubmit}>
                    <Form.Group className="mt-3 mb-3">
                        <label style={{ fontWeight: "bold" }}>Pet Image</label>
                        <div className="d-flex align-items-center gap-3">
                            {formData?.petImage && (
                                <img
                                    src={typeof formData?.petImage === "string" ? `${imageBaseUrl}${formData?.petImage}` : URL.createObjectURL(formData?.petImage)}
                                    alt="Profile"
                                    style={{
                                        width: "100px",
                                        height: "100px",
                                        objectFit: "cover",
                                        borderRadius: "8px"
                                    }}
                                />
                            )}
                            <div>
                                <input
                                    type="file"
                                    className="form-control"
                                    accept="image/*"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        if (file) {
                                            setFormData((prev) => ({
                                                ...prev,
                                                petImage: file,
                                            }));
                                        }
                                    }}
                                />
                                <small className="text-muted d-block mt-1">
                                    Supported formats: JPG, PNG
                                </small>
                                {imageLoading && <small>Uploading image...</small>}
                            </div>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Label style={{ fontWeight: "bold" }}>Pet Name</Form.Label>
                        <span className="text-danger">*</span>
                        <Form.Control
                            type="text"
                            name="name"
                            value={formData?.petName}
                            required
                            onChange={(e) => {
                                if (validations?.onlyLettersAllowed?.test(e.target.value)) {
                                    setFormData({ ...formData, petName: e.target.value });
                                }
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label style={{ fontWeight: "bold" }}>Breed</Form.Label>
                        <span className="text-danger">*</span>
                        <Form.Select
                            name="breed"
                            value={formData?.breed}
                            required
                            onChange={(e) => {
                                setFormData({ ...formData, breed: e.target.value });
                            }}
                        >
                            <option value="">Select Breed</option>
                            {breedOfPetsCategoryList?.filter(breed => breed.categoryId._id === formData?.petCategory._id).map((breed) => (
                                <option key={breed._id} value={breed._id}>
                                    {breed.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label style={{ fontWeight: "bold" }}>Pet Date of Birth</Form.Label>
                        <span className="text-danger">*</span>
                        <Form.Control
                            type="date"
                            name="dateOfBirth"
                            required
                            value={formData?.dateOfBirth ? new Date(formData?.dateOfBirth).toLocaleDateString('en-CA') : ""}
                            onClick={(e) => {
                                e.target.showPicker();
                            }}
                            onChange={(e) =>
                                setFormData({ ...formData, dateOfBirth: e.target.value })
                            }
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label style={{ fontWeight: "bold" }}>Gender</Form.Label>
                        <span className="text-danger">*</span>
                        <Form.Select name="gender" value={formData?.gender} required onChange={handleChange}>
                            <option value="unknown">Unknown</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label style={{ fontWeight: "bold" }}>Category</Form.Label>
                        <span className="text-danger">*</span>
                        <Form.Control
                            type="text"
                            name="category"
                            disabled
                            value={formData?.petCategory?.name || ""}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label style={{ fontWeight: "bold" }}>Colour</Form.Label>
                        <span className="text-danger">*</span>
                        <Form.Control
                            type="text"
                            name="colour"
                            required
                            value={formData?.colour}
                            onChange={(e) => {
                                if (validations?.onlyLettersAllowed?.test(e.target.value)) {
                                    setFormData({ ...formData, colour: e.target.value });
                                }
                            }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label style={{ fontWeight: "bold" }}>Weight (in Kg)</Form.Label>
                        <span className="text-danger">*</span>
                        <Form.Control
                            type="text"
                            name="weight"
                            value={formData?.weight}
                            required
                            maxLength={3}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*\.?\d*$/.test(value)) {
                                    setFormData({ ...formData, weight: value });
                                }
                            }}
                        />
                    </Form.Group>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "10px",
                        }}
                    >
                        <Button
                            variant="secondary"
                            onClick={(e) => {
                                handleClose();
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={loading}
                        >
                            {loading ? (
                                <>
                                    <div
                                        className="spinner-border text-primary mx-auto"
                                        role="status"
                                    ></div>
                                </>
                            ) : (
                                "Update"
                            )}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>


        </Modal>
    );
};

export default PetUpdateModal;