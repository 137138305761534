import axios from "axios";
import { errorHandling } from "../../helper";
import { toast } from "react-toastify";

// clinical

export const VET_DROPDOWN_CLINICAL_CREATE_REQUEST =
  "VET_DROPDOWN_CLINICAL_CREATE_REQUEST";
export const VET_DROPDOWN_CLINICAL_CREATE_SUCCESS =
  "VET_DROPDOWN_CLINICAL_CREATE_SUCCESS";
export const VET_DROPDOWN_CLINICAL_CREATE_FAILURE =
  "VET_DROPDOWN_CLINICAL_CREATE_FAILURE";

export const VET_DROPDOWN_CLINICAL_UPDATE_REQUEST =
  "VET_DROPDOWN_CLINICAL_UPDATE_REQUEST";
export const VET_DROPDOWN_CLINICAL_UPDATE_SUCCESS =
  "VET_DROPDOWN_CLINICAL_UPDATE_SUCCESS";
export const VET_DROPDOWN_CLINICAL_UPDATE_FAILURE =
  "VET_DROPDOWN_CLINICAL_UPDATE_FAILURE";

export const VET_DROPDOWN_CLINICAL_LIST_REQUEST =
  "VET_DROPDOWN_CLINICAL_LIST_REQUEST";
export const VET_DROPDOWN_CLINICAL_LIST_SUCCESS =
  "VET_DROPDOWN_CLINICAL_LIST_SUCCESS";
export const VET_DROPDOWN_CLINICAL_LIST_FAILURE =
  "VET_DROPDOWN_CLINICAL_LIST_FAILURE";

export const VET_DROPDOWN_CLINICAL_DELETE_REQUEST =
  "VET_DROPDOWN_CLINICAL_DELETE_REQUEST";
export const VET_DROPDOWN_CLINICAL_DELETE_SUCCESS =
  "VET_DROPDOWN_CLINICAL_DELETE_SUCCESS";
export const VET_DROPDOWN_CLINICAL_DELETE_FAILURE =
  "VET_DROPDOWN_CLINICAL_DELETE_FAILURE";

// pet health concern

export const PET_HEALTH_CONCERN_CREATE_REQUEST =
  "PET_HEALTH_CONCERN_CREATE_REQUEST";
export const PET_HEALTH_CONCERN_CREATE_SUCCESS =
  "PET_HEALTH_CONCERN_CREATE_SUCCESS";
export const PET_HEALTH_CONCERN_CREATE_FAILURE =
  "PET_HEALTH_CONCERN_CREATE_FAILURE";

export const PET_HEALTH_CONCERN_UPDATE_REQUEST =
  "PET_HEALTH_CONCERN_UPDATE_REQUEST";
export const PET_HEALTH_CONCERN_UPDATE_SUCCESS =
  "PET_HEALTH_CONCERN_UPDATE_SUCCESS";
export const PET_HEALTH_CONCERN_UPDATE_FAILURE =
  "PET_HEALTH_CONCERN_UPDATE_FAILURE";

export const PET_HEALTH_CONCERN_LIST_REQUEST =
  "PET_HEALTH_CONCERN_LIST_REQUEST";
export const PET_HEALTH_CONCERN_LIST_SUCCESS =
  "PET_HEALTH_CONCERN_LIST_SUCCESS";
export const PET_HEALTH_CONCERN_LIST_FAILURE =
  "PET_HEALTH_CONCERN_LIST_FAILURE";

export const PET_HEALTH_CONCERN_DELETE_REQUEST =
  "PET_HEALTH_CONCERN_DELETE_REQUEST";
export const PET_HEALTH_CONCERN_DELETE_SUCCESS =
  "PET_HEALTH_CONCERN_DELETE_SUCCESS";
export const PET_HEALTH_CONCERN_DELETE_FAILURE =
  "PET_HEALTH_CONCERN_DELETE_FAILURE";

// Speciality_DROPDOWN Certificates

export const SPECIALITY_DROPDOWN_CREATE_REQUEST =
  "SPECIALITY_DROPDOWN_CREATE_REQUEST";
export const SPECIALITY_DROPDOWN_CREATE_SUCCESS =
  "SPECIALITY_DROPDOWN_CREATE_SUCCESS";
export const SPECIALITY_DROPDOWN_CREATE_FAILURE =
  "SPECIALITY_DROPDOWN_CREATE_FAILURE";

export const SPECIALITY_DROPDOWN_UPDATE_REQUEST =
  "SPECIALITY_DROPDOWN_UPDATE_REQUEST";
export const SPECIALITY_DROPDOWN_UPDATE_SUCCESS =
  "SPECIALITY_DROPDOWN_UPDATE_SUCCESS";
export const SPECIALITY_DROPDOWN_UPDATE_FAILURE =
  "SPECIALITY_DROPDOWN_UPDATE_FAILURE";

export const SPECIALITY_DROPDOWN_LIST_REQUEST =
  "SPECIALITY_DROPDOWN_LIST_REQUEST";
export const SPECIALITY_DROPDOWN_LIST_SUCCESS =
  "SPECIALITY_DROPDOWN_LIST_SUCCESS";
export const SPECIALITY_DROPDOWN_LIST_FAILURE =
  "SPECIALITY_DROPDOWN_LIST_FAILURE";

export const SPECIALITY_DROPDOWN_DELETE_REQUEST =
  "SPECIALITY_DROPDOWN_DELETE_REQUEST";
export const SPECIALITY_DROPDOWN_DELETE_SUCCESS =
  "SPECIALITY_DROPDOWN_DELETE_SUCCESS";
export const SPECIALITY_DROPDOWN_DELETE_FAILURE =
  "SPECIALITY_DROPDOWN_DELETE_FAILURE";

//Treatments Offer At Home

export const TREATMENT_OFFER_DROPDOWN_CREATE_REQUEST =
  "TREATMENT_OFFER_DROPDOWN_CREATE_REQUEST";
export const TREATMENT_OFFER_DROPDOWN_CREATE_SUCCESS =
  "TREATMENT_OFFER_DROPDOWN_CREATE_SUCCESS";
export const TREATMENT_OFFER_DROPDOWN_CREATE_FAILURE =
  "TREATMENT_OFFER_DROPDOWN_CREATE_FAILURE";

export const TREATMENT_OFFER_DROPDOWN_UPDATE_REQUEST =
  "TREATMENT_OFFER_DROPDOWN_UPDATE_REQUEST";
export const TREATMENT_OFFER_DROPDOWN_UPDATE_SUCCESS =
  "TREATMENT_OFFER_DROPDOWN_UPDATE_SUCCESS";
export const TREATMENT_OFFER_DROPDOWN_UPDATE_FAILURE =
  "TREATMENT_OFFER_DROPDOWN_UPDATE_FAILURE";

export const TREATMENT_OFFER_DROPDOWN_LIST_REQUEST =
  "TREATMENT_OFFER_DROPDOWN_LIST_REQUEST";
export const TREATMENT_OFFER_DROPDOWN_LIST_SUCCESS =
  "TREATMENT_OFFER_DROPDOWN_LIST_SUCCESS";
export const TREATMENT_OFFER_DROPDOWN_LIST_FAILURE =
  "TREATMENT_OFFER_DROPDOWN_LIST_FAILURE";

export const TREATMENT_OFFER_DROPDOWN_DELETE_REQUEST =
  "TREATMENT_OFFER_DROPDOWN_DELETE_REQUEST";
export const TREATMENT_OFFER_DROPDOWN_DELETE_SUCCESS =
  "TREATMENT_OFFER_DROPDOWN_DELETE_SUCCESS";
export const TREATMENT_OFFER_DROPDOWN_DELETE_FAILURE =
  "TREATMENT_OFFER_DROPDOWN_DELETE_FAILURE";

//BloodTest

export const BLOODTEST_DROPDOWN_CREATE_REQUEST =
  "BLOODTEST_DROPDOWN_CREATE_REQUEST";
export const BLOODTEST_DROPDOWN_CREATE_SUCCESS =
  "BLOODTEST_DROPDOWN_CREATE_SUCCESS";
export const BLOODTEST_DROPDOWN_CREATE_FAILURE =
  "BLOODTEST_DROPDOWN_CREATE_FAILURE";

export const BLOODTEST_DROPDOWN_UPDATE_REQUEST =
  "BLOODTEST_DROPDOWN_UPDATE_REQUEST";
export const BLOODTEST_DROPDOWN_UPDATE_SUCCESS =
  "BLOODTEST_DROPDOWN_UPDATE_SUCCESS";
export const BLOODTEST_DROPDOWN_UPDATE_FAILURE =
  "BLOODTEST_DROPDOWN_UPDATE_FAILURE";

export const BLOODTEST_DROPDOWN_LIST_REQUEST =
  "BLOODTEST_DROPDOWN_LIST_REQUEST";
export const BLOODTEST_DROPDOWN_LIST_SUCCESS =
  "BLOODTEST_DROPDOWN_LIST_SUCCESS";
export const BLOODTEST_DROPDOWN_LIST_FAILURE =
  "BLOODTEST_DROPDOWN_LIST_FAILURE";

export const BLOODTEST_DROPDOWN_DELETE_REQUEST =
  "BLOODTEST_DROPDOWN_DELETE_REQUEST";
export const BLOODTEST_DROPDOWN_DELETE_SUCCESS =
  "BLOODTEST_DROPDOWN_DELETE_SUCCESS";
export const BLOODTEST_DROPDOWN_DELETE_FAILURE =
  "BLOODTEST_DROPDOWN_DELETE_FAILURE";

//Degree

export const DEGREE_DROPDOWN_CREATE_REQUEST = "DEGREE_DROPDOWN_CREATE_REQUEST";
export const DEGREE_DROPDOWN_CREATE_SUCCESS = "DEGREE_DROPDOWN_CREATE_SUCCESS";
export const DEGREE_DROPDOWN_CREATE_FAILURE = "DEGREE_DROPDOWN_CREATE_FAILURE";

export const DEGREE_DROPDOWN_UPDATE_REQUEST = "DEGREE_DROPDOWN_UPDATE_REQUEST";
export const DEGREE_DROPDOWN_UPDATE_SUCCESS = "DEGREE_DROPDOWN_UPDATE_SUCCESS";
export const DEGREE_DROPDOWN_UPDATE_FAILURE = "DEGREE_DROPDOWN_UPDATE_FAILURE";

export const DEGREE_DROPDOWN_LIST_REQUEST = "DEGREE_DROPDOWN_LIST_REQUEST";
export const DEGREE_DROPDOWN_LIST_SUCCESS = "DEGREE_DROPDOWN_LIST_SUCCESS";
export const DEGREE_DROPDOWN_LIST_FAILURE = "DEGREE_DROPDOWN_LIST_FAILURE";

export const DEGREE_DROPDOWN_DELETE_REQUEST = "DEGREE_DROPDOWN_DELETE_REQUEST";
export const DEGREE_DROPDOWN_DELETE_SUCCESS = "DEGREE_DROPDOWN_DELETE_SUCCESS";
export const DEGREE_DROPDOWN_DELETE_FAILURE = "DEGREE_DROPDOWN_DELETE_FAILURE";

//Vaccination

export const VACCIATION_DROPDOWN_CREATE_REQUEST = "VACCIATION_DROPDOWN_CREATE_REQUEST";
export const VACCIATION_DROPDOWN_CREATE_SUCCESS = "VACCIATION_DROPDOWN_CREATE_SUCCESS";
export const VACCIATION_DROPDOWN_CREATE_FAILURE = "VACCIATION_DROPDOWN_CREATE_FAILURE";

export const VACCIATION_DROPDOWN_UPDATE_REQUEST = "VACCIATION_DROPDOWN_UPDATE_REQUEST";
export const VACCIATION_DROPDOWN_UPDATE_SUCCESS = "VACCIATION_DROPDOWN_UPDATE_SUCCESS";
export const VACCIATION_DROPDOWN_UPDATE_FAILURE = "VACCIATION_DROPDOWN_UPDATE_FAILURE";

export const VACCIATION_DROPDOWN_LIST_REQUEST = "VACCIATION_DROPDOWN_LIST_REQUEST";
export const VACCIATION_DROPDOWN_LIST_SUCCESS = "VACCIATION_DROPDOWN_LIST_SUCCESS";
export const VACCIATION_DROPDOWN_LIST_FAILURE = "VACCIATION_DROPDOWN_LIST_FAILURE";

export const VACCIATION_DROPDOWN_DELETE_REQUEST = "VACCIATION_DROPDOWN_DELETE_REQUEST";
export const VACCIATION_DROPDOWN_DELETE_SUCCESS = "VACCIATION_DROPDOWN_DELETE_SUCCESS";
export const VACCIATION_DROPDOWN_DELETE_FAILURE = "VACCIATION_DROPDOWN_DELETE_FAILURE";

//Sub Degree

export const SUB_DEGREE_DROPDOWN_CREATE_REQUEST =
  "SUB_DEGREE_DROPDOWN_CREATE_REQUEST";
export const SUB_DEGREE_DROPDOWN_CREATE_SUCCESS =
  "SUB_DEGREE_DROPDOWN_CREATE_SUCCESS";
export const SUB_DEGREE_DROPDOWN_CREATE_FAILURE =
  "SUB_DEGREE_DROPDOWN_CREATE_FAILURE";

export const SUB_DEGREE_DROPDOWN_UPDATE_REQUEST =
  "SUB_DEGREE_DROPDOWN_UPDATE_REQUEST";
export const SUB_DEGREE_DROPDOWN_UPDATE_SUCCESS =
  "SUB_DEGREE_DROPDOWN_UPDATE_SUCCESS";
export const SUB_DEGREE_DROPDOWN_UPDATE_FAILURE =
  "SUB_DEGREE_DROPDOWN_UPDATE_FAILURE";

export const SUB_DEGREE_DROPDOWN_LIST_REQUEST =
  "SUB_DEGREE_DROPDOWN_LIST_REQUEST";
export const SUB_DEGREE_DROPDOWN_LIST_SUCCESS =
  "SUB_DEGREE_DROPDOWN_LIST_SUCCESS";
export const SUB_DEGREE_DROPDOWN_LIST_FAILURE =
  "SUB_DEGREE_DROPDOWN_LIST_FAILURE";

export const SUB_DEGREE_DROPDOWN_DELETE_REQUEST =
  "SUB_DEGREE_DROPDOWN_DELETE_REQUEST";
export const SUB_DEGREE_DROPDOWN_DELETE_SUCCESS =
  "SUB_DEGREE_DROPDOWN_DELETE_SUCCESS";
export const SUB_DEGREE_DROPDOWN_DELETE_FAILURE =
  "SUB_DEGREE_DROPDOWN_DELETE_FAILURE";

// vet dropdown create

export const createVetClinicalDropdown = (
  dropdown,
  setModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VET_DROPDOWN_CLINICAL_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/create/clinic/treatment/name/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: VET_DROPDOWN_CLINICAL_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, VET_DROPDOWN_CLINICAL_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// vet dropdown update

export const updateVetClinicalDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VET_DROPDOWN_CLINICAL_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/update/clinic/treatment/name/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: VET_DROPDOWN_CLINICAL_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, VET_DROPDOWN_CLINICAL_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

// vet dropdown list

export const vetClinicalDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: VET_DROPDOWN_CLINICAL_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/get/clinic/treatments/name/admin/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: VET_DROPDOWN_CLINICAL_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, VET_DROPDOWN_CLINICAL_LIST_FAILURE, error);
    }
  };
};

// vet dropdown delete

export const deleteVetClinicalDropdown = (id, setDeleteModal, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VET_DROPDOWN_CLINICAL_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/delete/clinic/treatment/name/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: VET_DROPDOWN_CLINICAL_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, VET_DROPDOWN_CLINICAL_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

// pet health concer

//  dropdown create

export const createPetHealthConcernDropdown = (
  dropdown,
  setModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PET_HEALTH_CONCERN_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/create/health/concern/name/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: PET_HEALTH_CONCERN_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, PET_HEALTH_CONCERN_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// update

export const updatePetHealthConcernDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PET_HEALTH_CONCERN_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/update/health/concern/name/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: PET_HEALTH_CONCERN_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, PET_HEALTH_CONCERN_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

//list

export const petHealthConcernDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: PET_HEALTH_CONCERN_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/get/health/concerns/name/admin/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: PET_HEALTH_CONCERN_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, PET_HEALTH_CONCERN_LIST_FAILURE, error);
    }
  };
};

// delete

export const deletePetHealthConcernDropdown = (
  id,
  setDeleteModal,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: PET_HEALTH_CONCERN_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/delete/health/concern/name/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: PET_HEALTH_CONCERN_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, PET_HEALTH_CONCERN_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

// Speciality Certificate

export const createSpecialityDropdown = (
  dropdown,
  setModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SPECIALITY_DROPDOWN_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/create/speciality/certificate/name/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: SPECIALITY_DROPDOWN_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, SPECIALITY_DROPDOWN_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// update

export const updateSpecialityDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SPECIALITY_DROPDOWN_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/update/speciality/certificate/name/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: SPECIALITY_DROPDOWN_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, SPECIALITY_DROPDOWN_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

//list

export const specialityDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: SPECIALITY_DROPDOWN_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/get/admin/speciality/certificates/name/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: SPECIALITY_DROPDOWN_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, SPECIALITY_DROPDOWN_LIST_FAILURE, error);
    }
  };
};

// delete

export const deleteSpecialityDropdown = (id, setDeleteModal, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SPECIALITY_DROPDOWN_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/delete/speciality/certificate/name/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: SPECIALITY_DROPDOWN_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, SPECIALITY_DROPDOWN_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

// treatment offer at home

//  dropdown create

export const createTreatmentOfferDropdown = (
  dropdown,
  setModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: TREATMENT_OFFER_DROPDOWN_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/create/treatment/offer/home/name/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: TREATMENT_OFFER_DROPDOWN_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, TREATMENT_OFFER_DROPDOWN_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// update

export const updateTreatmentOfferDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: TREATMENT_OFFER_DROPDOWN_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/update/treatment/offer/home/name/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: TREATMENT_OFFER_DROPDOWN_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, TREATMENT_OFFER_DROPDOWN_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

//list

export const treatmentOfferDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: TREATMENT_OFFER_DROPDOWN_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/get/treatments/offer/home/name/admin/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: TREATMENT_OFFER_DROPDOWN_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, TREATMENT_OFFER_DROPDOWN_LIST_FAILURE, error);
    }
  };
};

// delete

export const deleteTreatmentOfferDropdown = (
  id,
  setDeleteModal,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: TREATMENT_OFFER_DROPDOWN_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/delete/treatment/offer/home/name/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: TREATMENT_OFFER_DROPDOWN_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, TREATMENT_OFFER_DROPDOWN_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

// BloodTest

//  dropdown create

export const createBloodTestDropdown = (dropdown, setModalShow) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BLOODTEST_DROPDOWN_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/create/vet/blood/test/type/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BLOODTEST_DROPDOWN_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
    } catch (error) {
      errorHandling(dispatch, BLOODTEST_DROPDOWN_CREATE_FAILURE, error);
      setModalShow(false);
    }
  };
};

// update

export const updateBloodTestDropdown = (id, dropdown, setUpdateModalShow) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BLOODTEST_DROPDOWN_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/update/vet/blood/test/type/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BLOODTEST_DROPDOWN_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
    } catch (error) {
      errorHandling(dispatch, BLOODTEST_DROPDOWN_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
    }
  };
};

//list

export const bloodTestDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: BLOODTEST_DROPDOWN_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/get/all/vet/blood/test/type/for/admin/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BLOODTEST_DROPDOWN_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, BLOODTEST_DROPDOWN_LIST_FAILURE, error);
    }
  };
};

// delete

export const deleteBloodTestDropdown = (id, setDeleteModal) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BLOODTEST_DROPDOWN_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/delete/vet/blood/test/type/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: BLOODTEST_DROPDOWN_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
    } catch (error) {
      errorHandling(dispatch, BLOODTEST_DROPDOWN_DELETE_FAILURE, error);
      setDeleteModal(false);
    }
  };
};

// Degree Type

//  dropdown create

export const createDegreeDropdown = (dropdown, setModalShow, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DEGREE_DROPDOWN_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/create/vet/degree/type/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: DEGREE_DROPDOWN_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, DEGREE_DROPDOWN_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// update

export const updateDegreeDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DEGREE_DROPDOWN_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/update/vet/degree/type/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: DEGREE_DROPDOWN_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, DEGREE_DROPDOWN_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

//list

export const degreeDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: DEGREE_DROPDOWN_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/get/all/degree/types/admin/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: DEGREE_DROPDOWN_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, DEGREE_DROPDOWN_LIST_FAILURE, error);
    }
  };
};

// delete

export const deleteDegreeDropdown = (id, setDeleteModal, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DEGREE_DROPDOWN_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/delete/vet/degree/type/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: DEGREE_DROPDOWN_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, DEGREE_DROPDOWN_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

// Sub Degree Type

//  dropdown create

export const createSubDegreeDropdown = (
  dropdown,
  setModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUB_DEGREE_DROPDOWN_CREATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/create/vet/degree/name/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: SUB_DEGREE_DROPDOWN_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, SUB_DEGREE_DROPDOWN_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

// update

export const updateSubDegreeDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUB_DEGREE_DROPDOWN_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/update/vet/degree/name/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: SUB_DEGREE_DROPDOWN_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, SUB_DEGREE_DROPDOWN_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

//list

export const subdegreeDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUB_DEGREE_DROPDOWN_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/get/all/degree/names/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: SUB_DEGREE_DROPDOWN_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, SUB_DEGREE_DROPDOWN_LIST_FAILURE, error);
    }
  };
};

// delete

export const deleteSubDegreeDropdown = (id, setDeleteModal, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SUB_DEGREE_DROPDOWN_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/delete/vet/degree/name/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: SUB_DEGREE_DROPDOWN_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, SUB_DEGREE_DROPDOWN_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};

// Vaccination
export const createVaccinationDropdown = (
  dropdown,
  setModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VACCIATION_DROPDOWN_CREATE_FAILURE });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/create/vet/vaccine/type/v1`;
      const response = await axios.post(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: VACCIATION_DROPDOWN_CREATE_SUCCESS,
        payload: response.data,
      });
      setModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, VACCIATION_DROPDOWN_CREATE_FAILURE, error);
      setModalShow(false);
      setFormData({});
    }
  };
};

export const updateVaccinationDropdown = (
  id,
  dropdown,
  setUpdateModalShow,
  setFormData
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VACCIATION_DROPDOWN_UPDATE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/update/vet/vaccine/type/${id}/v1`;
      const response = await axios.put(url, dropdown, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: VACCIATION_DROPDOWN_UPDATE_SUCCESS,
        payload: response.data,
      });
      setUpdateModalShow(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, VACCIATION_DROPDOWN_UPDATE_FAILURE, error);
      setUpdateModalShow(false);
      setFormData({});
    }
  };
};

export const vaccinationDropdownList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: VACCIATION_DROPDOWN_LIST_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/get/all/vet/vaccine/type/admin/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: VACCIATION_DROPDOWN_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      errorHandling(dispatch, VACCIATION_DROPDOWN_LIST_FAILURE, error);
    }
  };
};

export const deleteVaccinationDropdown = (id, setDeleteModal, setFormData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: VACCIATION_DROPDOWN_DELETE_REQUEST });
      const url = `${process.env.REACT_APP_API_AWS_URL}/vet/delete/vet/vaccine/type/${id}/v1`;
      const response = await axios.delete(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: VACCIATION_DROPDOWN_DELETE_SUCCESS,
        payload: response.data,
      });
      setDeleteModal(false);
      setFormData({});
    } catch (error) {
      errorHandling(dispatch, VACCIATION_DROPDOWN_DELETE_FAILURE, error);
      setDeleteModal(false);
      setFormData({});
    }
  };
};
